import React from 'react';
import classnames from 'classnames';
import {useSelector} from 'react-redux';
import {formatCurrency, range} from 'helpers';
import {ReactComponent as CloseIcon} from "assets/close-filled-blue.svg"
import Carousel from "components/UI/carousel";
import {Flex} from 'components/layouts';
import {CustomerIncome} from "./components/customer-income/CustomerIncome";
import {selectChosenOffer, selectOffers, selectPeriod} from 'modules/car';
import {car} from 'redux/actions';
import {selectCurrentStep, selectIsMobile} from "modules/app/selectors";
import {QuestionaryStepType} from "../AppForm/constants";

import './styles.sass';

export const Offers = ({ isCarouselEnabled }) => {
  const offers = useSelector(selectOffers);
  const period = useSelector(selectPeriod);
  const currentStep = useSelector(selectCurrentStep);
  const chosenOffer = useSelector(selectChosenOffer);
  const isMobile = useSelector(selectIsMobile);
  const creditOffers = React.useMemo(() => Object.entries(offers), [offers]);

  let carouselRef = React.useRef();

  const EmptyPlaceholder = () => {
    return (
      <Flex className="offer" column>
        <Flex
          alignCenter
          column
          className="offer-year placeholder"
        >
          <Flex relative fullWidth center className="offer-header" />
          <Flex column f1 center className="offer-body">
            <h4>Нет предложений</h4>
          </Flex>
        </Flex>
      </Flex>
    )
  };

  const setInitialSlide = (slidesToShow) => isMobile ? creditOffers.length - 1 : Math.ceil(creditOffers.length / slidesToShow) - 1;

  const carouselOptions = {
    initialSlide: setInitialSlide(4),
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: setInitialSlide(3),
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: setInitialSlide(2),
          dots: true
        }
      }
    ]
  }

  const carouselEnabledClassName = isCarouselEnabled ? '' : 'offers__blocked';

  const updateChosen = (period, key, offer) => {
    const chosen = period === key ? null : offer;

    car.update({
      period: period === key ? null : key,
      chosen
    })

    if (currentStep === QuestionaryStepType.INFO){
      car.update({firstChosen: chosen});
    }
  };


  return (
    <Flex
      relative
      column
      className="offers"
      id="offersBlock"
    >
      {!creditOffers.length && <div className="offers-container offers-container--empty">
        {Array(4).fill(<></>).map((value, index) => <EmptyPlaceholder key={index} index={index} />) || ''}
      </div>}
      {creditOffers.length &&
      <Carousel ref={(el) => (carouselRef = el)}
          options={carouselOptions}
          className={`offers-container ${carouselEnabledClassName}`}>
        {creditOffers.map(([key, offer]) => {
            const isActive = period === key;
            return (
                <Flex key={key} className="offer" column>
                  <Flex
                    alignCenter
                    column
                    className={classnames('offer-year', {'active': isActive})}
                    onClick={() => {
                      updateChosen(period, key, offer);
                    }}
                  >
                    <Flex relative fullWidth center className={classnames( "offer-header", { "offer-header_active": isActive })}>
                      <div>{range(key)}</div>
                      <CloseIcon symbol="close-filled-blue" className={classnames("plus", {"plus_active": isActive})} />
                    </Flex>
                    <Flex column f1 center className="offer-body">
                      {Object.keys(offers[key]).length === 0 ? (
                        <div className="offers__last-payment">Нет расчетов</div>
                      ) : (
                        <>
                          <h4>Платёж, ₽</h4>
                          <div className="offer-price">
                            {formatCurrency(Math.round(offers[key].payment))}
                          </div>
                          <div className="hr"/>
                          <div className="offer-percent">
                            {offer.interest_rate} %
                          </div>
                          <div className="offers__last-payment">
                            {offer.bayback_price > 0 ? `Последний платеж: ${formatCurrency(Math.round(offer.bayback_price))}` : ``}
                          </div>
                        </>
                      )}
                    </Flex>
                  </Flex>
                  {Object.keys(offers[key]).length !== 0 && (
                    <CustomerIncome monthlyPayment={Math.round(offers[key].payment)} isVisible={isActive}/>
                  )}
                </Flex>
            )
          }
        )}
      </Carousel> || ''}
      {/*{creditOffers.length && <Carousel
        ref={(el) => (carouselRef = el)}
        className={`offers-container ${carouselEnabledClassName}`}
        responsive={responsive}
        customButtonGroup={<CarouselButtonGroup/>}
        renderButtonGroupOutside
        renderDotsOutside
        arrows={false}
        showDots={false}
      >
        {creditOffers.map(([key, offer]) => {
            const isActive = period === key;
            return (
              <Flex className="offer" key={key} column>
                <Flex
                  alignCenter
                  column
                  className={classnames('offer-year', {'active': isActive})}
                  onClick={() => {
                    updateChosen(period, key, offer);
                  }}
                >
                  <Flex relative fullWidth center className={classnames( "offer-header", { "offer-header_active": isActive })}>
                    <div>{range(key)}</div>
                    <CloseIcon symbol="close-filled-blue" className={classnames("plus", {"plus_active": isActive})} />
                  </Flex>
                  <Flex column f1 center className="offer-body">
                    <h4>Платёж, ₽</h4>
                    <div className="offer-price">
                      {formatCurrency(Math.round(offers[key].payment))}
                    </div>
                    <div className="hr"/>
                    <div className="offer-percent">
                      {offer.interest_rate} %
                    </div>
                    <div className="offers__last-payment">
                      {offer.bayback_price > 0 ? `Последний платеж: ${formatCurrency(Math.round(offer.bayback_price))}` : ``}
                    </div>
                  </Flex>
                </Flex>
                <CustomerIncome monthlyPayment={Math.round(offers[key].payment)} isVisible={isActive}/>
              </Flex>

            )
          }
        ) || ''}
      </Carousel> || ''}*/}
      {chosenOffer && <div className="customer-income customer-income_mobile">Ваш доход в месяц не менее {formatCurrency(chosenOffer.payment)} руб.</div> }
    </Flex>
  );
};
