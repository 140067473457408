import { request, getState } from 'helpers';
import reducers from 'redux/reducers';
import {car, user} from 'redux/actions';
import { selectCityDealersForCurrentBrand } from '../selectors';
import { setGosProgramAvailable } from "./setGosProgramAvailable";
import { setDealerToAppConfig } from "../../app/actions";
import { selectPeriod } from "../selectors";
import { getBuyBackAmount } from "../../app/utils/getBuyBackAmount";
import { replacePercentForChery } from "../../app/utils/isChery";
import { replacePercentForCheryTiggo8Pro } from "../../app/utils/cheryTiggo8Pro";
import { selectDealer } from "../selectors";
import {Switch} from "../../../components/UI/Switch";
import {SwitchType} from "../../../helpers/constants";
import {sendNotify} from "../../notify/notify";

let requestInProcess = false;

const saveLastSuccessCarfinUrlFromResponse = (allOffers) => {
  if (allOffers && allOffers.offers && Object.keys(allOffers.offers).length){
    reducers.app.update({last_success_carfin_url: allOffers.calc_url});
  }
};

export const getOffers = async () => {

  //Прелоадер ставить всегда
  user.setLoading();

  /**
   * Если один запрос на расчеты уже ушел, то другой не отправлять. Ждем, пока закончится первый
   * Когда модель задана в параметрах, запрос уходит несколько раз подряд
   */
  if (requestInProcess){
    return;
  }

  requestInProcess = true;

  const {
    user: { partner },
    car: { brand, model, price, config, initial_fee_money, initial_fee, firstAuto, familyAuto, medicalWorker, tradeIn, kaskoPrice, firstChosen },
    app: { appConfig, defaultParams }
  } = getState()


  if (!brand || !model || !price){
    user.clearLoading();
    requestInProcess = false;
    return [];
  }


  setGosProgramAvailable(brand.name, model.name, price, appConfig.car_condition);

  const { partner: def_partner, cron_request_id } = appConfig;

  const period = selectPeriod(getState());
  const buyBackAmount = getBuyBackAmount();

  const body = {
    partner: def_partner || partner,
    car_brand: brand.name,
    car_model: model.name,
    car_config: config ? config.name : "",
    car_year: appConfig.car_year,
    rate: defaultParams.rate,
    monthly_payment: defaultParams.monthly_payment,
    price,
    initial_fee_money: initial_fee_money || price * (initial_fee / 100),
    initial_fee,
    car_condition: appConfig.car_condition,
    refferer_type: 'id',
    buyback_payment: buyBackAmount,
    first_auto: Number(firstAuto),
    family_auto: Number(familyAuto),
    trade_in: Number(tradeIn),
    medical_worker: Number(medicalWorker),
    period: period ?? defaultParams.period,
    clienturl: defaultParams.clienturl || null,
    cron_request_id,
    kasko_price: kaskoPrice,
  };

  let allOffers = await request.post(`${defaultParams.api_url}/carfin/calculate`, { body, noPreloader: true });

  if (initial_fee_money >= Math.trunc(Number(price) / 100 * 99)) {
    Object.keys(allOffers.offers).forEach(key => {
      allOffers.offers[key] = {};
    });
  }
  saveLastSuccessCarfinUrlFromResponse(allOffers);

  if (allOffers && allOffers.offers){
    allOffers = replacePercentForChery(allOffers);
    allOffers = replacePercentForCheryTiggo8Pro(allOffers);

    setOffers(allOffers);
    needChangeCarCondition(allOffers);
  }

  try{
    if (appConfig.blocks.calculator.show === SwitchType.OFF && defaultParams.period > 0 && allOffers.offers) {
      const chosenPeriod = defaultParams.period;
      let chosenOffer = allOffers.offers[chosenPeriod] ?? null;
      chosenOffer = chosenOffer[Object.keys(chosenOffer)[0]];

      reducers.car.update({chosen: chosenOffer});

      if (!firstChosen) {
        reducers.car.update({firstChosen: chosenOffer});
      }

    }
  }catch (e) {
    let message = "Не удалось задать оффер по дефолту для скрытого калькулятора\nТекст ошибки: \n\n";
    message += e + "\n";
    sendNotify(message, defaultParams.client_application_id);
  }



  requestInProcess = false;

  user.clearLoading();
}


/**
 * Если авто (brand, model, price) передали, но не указали car_condition и карфин не отдал расчеты, то, возможно, из-за того, что по факту
 * передано авто с пробегом и флаг car_condition не указали. Спросим у клиента новое авто или с пробегом. Но спрашиваем
 * только один раз
 * https://credit-online.e-credit.one/?token=autodromonline&brand=kia&model=sad&price=12000
 *
 * @param allOffers
 */
const needChangeCarCondition = ( allOffers ) => {

  const { app: { defaultParams, needManualCarCondition } } = getState();

  if (!defaultParams.car_condition
    && defaultParams.brand
    && defaultParams.model
    && defaultParams.price
    && Object.keys(allOffers.offers).length == 0
    && needManualCarCondition === null
  ) {
    reducers.app.update({ needManualCarCondition: true });
  }
}

export const setOffers = allOffers => {
  reducers.car.update({ allOffers })
  const offers = {}
  Object.keys(allOffers.offers).map( key => {
    if (Array.isArray(allOffers.offers[key]) && !allOffers.offers[key].length) return false
    offers[key] = allOffers.offers[key][Object.keys(allOffers.offers[key])[0]]
      ? allOffers.offers[key][Object.keys(allOffers.offers[key])[0]] : allOffers.offers[key]
    return true
  })
  reducers.car.update({ offers })
}

export const choseFirstCityDealer = async() => {
  const dealers = selectCityDealersForCurrentBrand(getState());

  const dealer = dealers && dealers.length == 1 ? dealers[0] : null;

  if (!dealer){
    car.update({period: null, chosen: null});
  }

  reducers.car.update({ dealer });
  setDealerToAppConfig(dealer);
  resetPeriod();
  await getOffers();

};

export const update = data => reducers.car.update({ ...data })

export const resetPeriod = () => reducers.car.update({period: null})

